import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Creator } from './pages/creator/Creator.component';
import { Demo } from 'pages/demo/Demo.component';
import { IntlProvider } from 'react-intl';
import UserContext from './common/userContext';
import { CarDamageOCIssues } from 'pages/carDamageOCIssues/CarDamageOCIssues.component';
import { StartOffer } from 'pages/startOffer/StartOffer.component';
import { ErrorBoundary } from 'react-error-boundary';
import { StatusProvider } from 'common/statusContext';
import { VolvoErrorPag } from './pages/errorPage/VolvoErrorPage.component';
import { AppErrorProvider } from './common/appErrorContext';
import { ModifyPriceList } from 'pages/priceListImport/modifyPriceList/ModifyPriceList.component';
import { PriceListImportContainer } from 'pages/priceListImport/priceListImportContainer/PriceListImportContainer.component';
import { NewSpecialOffer } from 'pages/specialOffer/newSpecialOfferForm/NewSpecialOfferForm';
import { SpecialOfferPreview } from 'pages/specialOffer/specialOfferPreview/SpecialOfferPreview';
import { FinancialProducts } from 'pages/financialProducts/FinancialProducts.component';
import { FinancialProductPreview } from 'pages/financialProducts/financialProductsPreview/FinancialProductPreview.component';
import { PriceListImportWrapper } from 'pages/priceListImport/PriceListImportWrapper.component';
import { SupplementSpecialOfferForm } from 'pages/specialOffer/supplementSpecialOfferForm/SupplementSpecialOfferForm';
import { SpecialOfferSearchPreview } from 'pages/specialOffer/specialOfferPreview/SpecialOfferSearchPreview';
import { SettlementSpecialOfferForm } from 'pages/specialOffer/settlementSpecialOfferForm/SettlementSpecialOfferForm';
import { EditSpecialOffer } from 'pages/specialOffer/editSpecialOffer/EditSpecialOffer';
// import { EmployeeLeasingStart } from 'pages/startOffer/EmployeeLeasingStart';
import { EmployeeLeasingStartOffer } from 'pages/startOffer/EmployeeLeasingStartOffer';
import { EmployeeLeasingCreator } from 'pages/creator/EmployeeLeasingCreator';
import { EmployeeLeasingAdminStartOffer } from 'pages/startOffer/EmployeeLeasingAdminStart';
export default function Root() {
    return (React.createElement(Router, null,
        React.createElement(ErrorBoundary, { fallbackRender: function (_a) {
                var error = _a.error, resetErrorBoundary = _a.resetErrorBoundary;
                return React.createElement(VolvoErrorPag, { text: 'Wystąpił nieoczekiwany błąd.', resetErrorBoundary: resetErrorBoundary });
            } },
            React.createElement(IntlProvider, { locale: "pl" },
                React.createElement(StatusProvider, null,
                    React.createElement(AppErrorProvider, null,
                        React.createElement(App, null)))))));
}
export function App() {
    var forceUpdate = useForceUpdate();
    var location = useLocation();
    return (React.createElement("div", { className: "screen", key: forceUpdate },
        React.createElement("style", null, ":root {\n            --blue: #1C6BBA;\n            --main-text-color: #333333;\n            --white-text-color: #FFFFFF;\n            --blue-text-color: #284E80;\n            --blue-text-color-disabled: #91A4BD;\n            --text-grey: #B0B0B0;\n            --grey: #707070;\n            --text-disabled-input: #D5D5D5;\n            --text-light-grey: #D5D5D5;\n            --background-very-light-grey: #FAFAFA;\n            --line-light-grey: #D5D5D5;\n            --calendar-font-grey: #B0B0B0;\n            --calendar-font-disabled-grey: #E8E8E8;\n            --black-text-color: #141414;\n            --font-family: 'Volvo Novum';\n            --bg-selected-odd-item: rgba(28, 107, 186, 0.1);\n            --bg-selected-even-item: rgba(28, 107, 186, 0.05);\n            --light-grey: #FAFAFA;\n            --checkbox-width: 20px;\n            --checkbox-height: 20px;\n            --radio-width: 20px;\n            --radio-height: 20px;\n            --visa-price-color: #BE834A;\n            --white: #FFFFFF;\n            --button-primary-default-bg: #284E80;\n            --button-primary-dissabled-bg: #91A4BD;\n            --button-primary-hover-bg: #527198;\n            --button-primary-active-bg: #254570;\n            --button-secondary-default-bg: #FFFFFF;\n            --button-secondary-dissabled-border: #91A4BD;\n            --button-secondary-hover-border: #E5E9EE;\n            --dropdown-bg: #FFFFFF;\n            --dropdown-disabled-bg: #f2f2f2;\n            --dropdown-border-color: #D5D5D5;\n            --validate-color: #BF2012;\n            --paginate-items-bg: #EBEBEB;\n            --wizard-step-done: #284E80;\n            --wizard-step-inprogress: #D9D9D9;\n            --middle-grey: #6D6D6D;\n            --grey: #EBEBEB;\n            --line-grey-darker: #C4C4C4;\n          }"),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/start-offer" },
                React.createElement(StartOffer, null)),
            React.createElement(Route, { exact: true, path: "/employee-leasing-start-offer" }, UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING', 'EMPLOYEE_LEASING') ? (React.createElement(EmployeeLeasingStartOffer, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/employee-leasing-start-offer-admin" }, UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING_ADMIN') ? (React.createElement(EmployeeLeasingAdminStartOffer, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/employee-leasing-creator" }, UserContext.hasAnyRole('VCP_EMPLOYEE_LEASING', 'EMPLOYEE_LEASING', 'VCP_EMPLOYEE_LEASING_ADMIN') ? (React.createElement(EmployeeLeasingCreator, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/error" },
                React.createElement(VolvoErrorPag, null)),
            React.createElement(Route, { exact: true, path: "/creator" },
                React.createElement(Creator, null)),
            React.createElement(Route, { exact: true, path: "/demo" },
                React.createElement(Demo, null)),
            React.createElement(Route, { exact: true, path: "/car-damage-oc-issues" }, UserContext.hasAnyRole('OC_REGISTRATION_ADMINISTRATOR', 'VCP_CUSTOMER_SERVICE') ? (React.createElement(CarDamageOCIssues, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/price-lists" }, UserContext.hasAnyRole('VCP_PRODUCT') ? (React.createElement(PriceListImportWrapper, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/price-list/:priceListName/modify" }, UserContext.hasAnyRole('VCP_PRODUCT') ? (React.createElement(ModifyPriceList, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/price-list-import" }, UserContext.hasAnyRole('VCP_PRODUCT') ? (React.createElement(PriceListImportContainer, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/special-offer/preview" }, UserContext.hasAnyRole('SALES', 'HEAD_OF_SALES', 'CUSTOMER_SERVICE', 'VCP_FLEET_SALES', 'VCP_SALES_MANAGER', 'VCP_SALES', 'INVOICING', 'BACK_OFFICE', 'VCP_ACCOUNTING', 'VCP_AUDIT', 'VCP_HEAD_OF_ACCOUNTING') ? (React.createElement(SpecialOfferPreview, null)) : (React.createElement(Redirect, { to: UserContext.isLoggedIn() ? '/login' : "/login?next=".concat(location.pathname).concat(location.search) }))),
            React.createElement(Route, { exact: true, path: "/special-offer/new" }, UserContext.hasAnyRole('SALES') ? (React.createElement(NewSpecialOffer, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/special-offer/supplement" }, UserContext.hasAnyRole('SALES') ? (React.createElement(SupplementSpecialOfferForm, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/special-offer/search-preview" }, UserContext.hasAnyRole('SALES', 'HEAD_OF_SALES', 'INVOICING', 'BACK_OFFICE', 'VCP_SALES', 'VCP_ACCOUNTING', 'VCP_AUDIT', 'VCP_FLEET_SALES', 'VCP_HEAD_OF_ACCOUNTING') ? (React.createElement(SpecialOfferSearchPreview, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/special-offer/settlement" }, UserContext.hasAnyRole('SALES') ? (React.createElement(SettlementSpecialOfferForm, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/special-offer/edit" }, UserContext.hasAnyRole('SALES') ? (React.createElement(EditSpecialOffer, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/financial-products" }, UserContext.hasAnyRole('VCP_ACCOUNTING') ? (React.createElement(FinancialProducts, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/financial-products/:productId" }, UserContext.hasAnyRole('VCP_ACCOUNTING') ? (React.createElement(FinancialProductPreview, null)) : (React.createElement(Redirect, { to: "/login" }))),
            React.createElement(Route, { exact: true, path: "/" },
                React.createElement(Redirect, { to: "/login" })))));
}
function useForceUpdate() {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var listener = function () { return setValue(function (prev) { return prev + 1; }); };
        window.addEventListener('force-reload', listener);
        return function () { return window.removeEventListener('force-reload', listener); };
    }, []);
    return value;
}
